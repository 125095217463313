/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

import styled from "@emotion/styled";
import { Box, Drawer, List, ListItem, ListItemButton } from "@mui/material";
import Link from "next/link";

import { useAuthentication } from "@fllite/ui/hooks";
import { UserBox } from "@fllite/ui/components";

import { HomeBaseForm } from "./HomeBaseForm";
import { menuItems, mobileExtendedMenuItems } from "./menuItems";
import { Burger, Line } from "./Navigation.style";

const MenuButton = styled(ListItemButton)`
  font-size: 16px;
  color: ${({ theme }) => (theme as any).colors.primary};
  text-decoration: none;
`;

export const Mobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useAuthentication();

  const handleBurgerClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <UserBox />
      {user !== false && user?.confirmed && (
        <>
          <Burger
            data-testid="Burger-button"
            onClick={handleBurgerClick}
            isOpen={isOpen}
          >
            <Line />
            <Line />
            <Line />
          </Burger>
          <Drawer
            open={isOpen}
            onClose={() => setIsOpen(false)}
            anchor="right"
            PaperProps={{ sx: { width: "80%" } }}
          >
            <Burger
              data-testid="Burger-button"
              onClick={handleBurgerClick}
              isOpen={isOpen}
            >
              <Line />
              <Line />
              <Line />
            </Burger>
            <Box sx={{ marginTop: "68px" }}>
              <List>
                {[...menuItems, ...mobileExtendedMenuItems].map((menuItem) => (
                  <ListItem key={menuItem.id}>
                    <Link passHref href={menuItem.href} legacyBehavior>
                      <MenuButton>{menuItem.title}</MenuButton>
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box sx={{ padding: "16px" }}>
              <HomeBaseForm />
            </Box>
          </Drawer>
        </>
      )}
    </>
  );
};
