import "@fllite/shared/yupConfig";

import dot from "dot-object";
import { ObjectSchema, ValidationError } from "yup";

export const validateAgainstSchema =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (schema: ObjectSchema<any> | any) => (values: object) => {
    return schema
      .validate(values, { abortEarly: false })
      .then(() => {
        return undefined;
      })
      .catch((err: ValidationError) =>
        err.inner?.reduce((acc, error: ValidationError) => {
          const errorMessage = error.errors[0];

          dot.str(error.path ?? "{unknown_path}", errorMessage, acc);
          return acc;
        }, {}),
      );
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasFieldError = (meta: any, forceIsErrorShown?: boolean) =>
  forceIsErrorShown != null
    ? forceIsErrorShown && meta.error != null
    : meta.error != null && (meta.touched || meta.submitFailed);
