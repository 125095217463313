import { FC } from "react";

import { TextFieldElement, useFormContext } from "react-hook-form-mui";

interface NumberFieldProps {
  name: string;
  placeholder: string;
  endAdornment: string;
  isAcceptFraction?: boolean;
}

export const NumberField: FC<NumberFieldProps> = ({
  name,
  placeholder,
  endAdornment,
  isAcceptFraction = false,
}) => {
  const { setValue } = useFormContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (evt: any) => {
    const value = evt.target.value;
    const allowedCharacters = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    isAcceptFraction && allowedCharacters.push(".");
    let cnt = 0;

    const res = value.split("").reduce((acc: string, curr: string) => {
      curr === "." && cnt++;
      if (cnt > 1 && curr === ".") return acc;
      return allowedCharacters.includes(curr) ? acc + curr : acc;
    }, "");
    setValue(name, res);
  };
  return (
    <TextFieldElement
      name={name}
      placeholder={placeholder}
      InputProps={{
        endAdornment: endAdornment,
      }}
      fullWidth
      variant="standard"
      onChange={onChange}
    />
  );
};
