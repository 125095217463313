import { AppSettingJsonldAppSettingCollectionType } from "@fllite/api";

import { AppSettings } from "../types";

export const getValueFromAppSettings = (
  appSettings: AppSettings[],
  type: AppSettingJsonldAppSettingCollectionType,
) => {
  return appSettings.find((item) => item.type === type)?.value ?? "-";
};
