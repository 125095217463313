import { NavigationLink } from "@/components/Navigation/NavigationLink";
import Image from "next/image";

import { Text } from "@fllite/ui/components";

import {
  BottomContent,
  Container,
  Content,
  CopyWrapper,
  FlliteLogo,
  Logos,
  Navigation,
  NBAA,
  NBAALogo,
  PaymentMethods,
  Separator,
  TopContent,
  UpperLine,
} from "./Footer.styles";

const year = new Date().getFullYear();

const isProd = process.env.NODE_ENV === "production";

export const Footer = () => (
  <Container data-testid="footer">
    <Content>
      <TopContent>
        <NavigationLink useActive={false} href="/" dataTestid="logo-footer">
          <FlliteLogo type="logo-light-blue" />
        </NavigationLink>
        <Navigation>
          <NavigationLink
            href="/contact-us"
            dataTestid="footer-contact"
            medium={false}
            primaryMedium
            useActive={false}
          >
            Contact Us
          </NavigationLink>
          <NavigationLink
            href="/privacy"
            dataTestid="footer-privacy"
            medium={false}
            primaryMedium
          >
            Privacy Policy
          </NavigationLink>
          <NavigationLink
            href="/terms"
            dataTestid="footer-terms"
            medium={false}
            primaryMedium
          >
            Terms & Conditions
          </NavigationLink>
        </Navigation>
        {isProd ? (
          <NBAA>
            <NBAALogo type="nbaa-blue" />
          </NBAA>
        ) : (
          <Logos>
            <NBAA>
              <NBAALogo type="nbaa-blue" />
            </NBAA>
            <PaymentMethods>
              <Image
                src="/assets/payment-methods-universal.webp"
                width={256}
                height={32}
                alt="Payment methods"
              />
            </PaymentMethods>
          </Logos>
        )}
      </TopContent>
      <Separator />
      <BottomContent>
        <UpperLine>
          <Text primaryMedium subtext>
            Fllite is not an air carrier. Fllite acts as an agent for our
            members to coordinate aircraft from FAA/DOT approved operators.
          </Text>
          <CopyWrapper>
            <Text primaryMedium subtext>
              © {year} Fllite Inc. All rights reserved.
            </Text>
          </CopyWrapper>
        </UpperLine>
      </BottomContent>
    </Content>
  </Container>
);
