import { Theme, useMediaQuery, useTheme } from "@mui/material";
import Link from "next/link";

import { useAuthentication } from "@fllite/ui/hooks";

import { Desktop } from "./Desktop";
import { HomeBaseForm } from "./HomeBaseForm";
import { Mobile } from "./Mobile";
import {
  Container,
  Content,
  LeftSideWrap,
  Logo,
  LogoWrapper,
  RightSideWrap,
} from "./Navigation.style";

export const Navigation = () => {
  const theme = useTheme() as Theme;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isDesktopMatch = useMediaQuery((theme as any).breakpoints.up("md"));
  const { user } = useAuthentication();

  return (
    <Container>
      <Content>
        <LeftSideWrap>
          <Link passHref href="/" legacyBehavior>
            <LogoWrapper>
              <Logo
                type={isDesktopMatch ? "logo-white" : "logo-white-mobile"}
              />
            </LogoWrapper>
          </Link>
          {isDesktopMatch && user !== false && user?.confirmed && (
            <HomeBaseForm />
          )}
        </LeftSideWrap>
        <RightSideWrap>
          {isDesktopMatch ? <Desktop /> : <Mobile />}
        </RightSideWrap>
      </Content>
    </Container>
  );
};
