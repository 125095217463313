export const getTripStatusColor = (
  status: string,
  contractSignStatus?: string,
) => {
  if (contractSignStatus && status === "proposal_approved") {
    switch (contractSignStatus) {
      case "awaiting_contract_signing":
      case "awaiting_charter_approval":
      case "awaiting_payment":
        return "secondary";
      case "contract_approved":
        return "success";
      case "contract_declined":
        return "error";
      case "contract_prepared":
        return "primary";
      default:
        return "warning";
    }
  }
  switch (status) {
    case "inquiry_received":
    case "proposal_received":
      return "warning";
    case "proposal_requested_by_user":
    case "proposal_requested":
    case "proposal_created":
    case "payment_confirmation":
    case "automatic_proposal_requested":
    case "manual_proposal_requested":
    case "awaiting_payment":
      return "warningDark";
    case "trip_booked":
    case "proposal_sent":
    case "proposal_approved":
      return "primary";
    case "fulfilled":
    case "paid":
      return "success";
    case "canceled":
    case "canceled_by_user":
    case "canceled_by_operator":
      return "error";
    case "expired":
      return "lightDark";
    default:
      return "warning";
  }
};
