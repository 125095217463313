import { useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  TextField,
  TextFieldProps,
} from "@mui/material";
import {
  Controller,
  ControllerProps,
  Path,
  useFormContext,
} from "react-hook-form";
import { FieldValues } from "react-hook-form";
import { PulseLoader } from "react-spinners";

import { Icon, IconKeys } from "@fllite/ui/components";
import { AirportsMenuOption } from "@fllite/ui/mui-form-fields";

export type HomeBaseSelectProps<
  F extends FieldValues,
  T,
  M extends boolean | undefined,
  D extends boolean | undefined,
> = {
  name: Path<F>;
  options: T[];
  loading?: boolean;
  multiple?: M;
  rules?: ControllerProps["rules"];
  required?: boolean;
  label?: TextFieldProps["label"];
  autocompleteProps?: Omit<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AutocompleteProps<T, M, D, any>,
    "name" | "options" | "loading" | "renderInput"
  >;
  textFieldProps?: Omit<TextFieldProps, "name" | "required" | "label">;
  withHeading?: string;
  icon?: IconKeys;
  isLoading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customOnChange?: (val: any) => void;
};

type AutoDefault = {
  id: string | number; // must keep id in case of keepObject
  label: string;
};

const CustomArrow = styled(KeyboardArrowDown)`
  transition: transform 0.2s;
  fill: white;
`;

export default function HomeBaseSelect<TFieldValues extends FieldValues>({
  textFieldProps,
  autocompleteProps,
  name,
  options,
  loading,
  rules,
  required,
  multiple,
  label,
  icon,
  isLoading,
  customOnChange,
}: HomeBaseSelectProps<
  TFieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  AutoDefault | string | any,
  boolean | undefined,
  boolean | undefined
>) {
  const { watch, setValue } = useFormContext();
  const value = watch(name);

  const [originValue, setOriginValue] = useState();

  const validationRules: ControllerProps["rules"] = {
    ...rules,
    ...(required && {
      required: rules?.required || "This field is required",
    }),
  };

  const lastValueInCustomOnChange = useRef(null as null | string);
  const wasClickToOptionItem = useRef(false);

  useEffect(() => {
    if (value) {
      setOriginValue(value);
    }
  }, []);

  return (
    <Controller
      name={name}
      rules={validationRules}
      render={({
        field: { onChange, onBlur, value, ref, ...fieldRest },
        fieldState: { error },
      }) => {
        const currentValue = multiple ? value || [] : value || null;
        return (
          <Autocomplete
            {...autocompleteProps}
            value={currentValue}
            loading={loading}
            multiple={multiple}
            options={options}
            // disableClearable={disableClearable as any}
            popupIcon={<CustomArrow />}
            disableCloseOnSelect={
              typeof autocompleteProps?.disableCloseOnSelect === "boolean"
                ? autocompleteProps.disableCloseOnSelect
                : !!multiple
            }
            isOptionEqualToValue={(option, v) => {
              return v ? option.value?.id === (v?.value.id || v) : false;
            }}
            getOptionLabel={(option) => {
              return `${option?.value?.name || option}`;
            }}
            onChange={(event, val) => {
              const changedVal = val;
              onChange(changedVal);
              const wasAlreadyCallWithThisValue =
                JSON.stringify(changedVal) ===
                lastValueInCustomOnChange?.current;
              if (customOnChange && !wasAlreadyCallWithThisValue) {
                lastValueInCustomOnChange.current = JSON.stringify(changedVal);
                customOnChange({ [name]: changedVal });
              }
            }}
            renderOption={(props, option) => (
              <li
                {...props}
                onMouseDown={() => {
                  wasClickToOptionItem.current = true;
                }}
              >
                <AirportsMenuOption {...option} selectHasIcon={true} />
              </li>
            )}
            onFocus={(event) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              setValue(name, null as any);
              if (typeof autocompleteProps?.onFocus === "function") {
                autocompleteProps.onFocus(event);
              }
            }}
            onBlur={(event) => {
              if (!value && originValue && !wasClickToOptionItem.current) {
                setValue(name, originValue);
              }
              wasClickToOptionItem.current = false;
              onBlur();
              if (typeof autocompleteProps?.onBlur === "function") {
                autocompleteProps.onBlur(event);
              }
            }}
            onInputChange={(event, newInputValue) => {
              autocompleteProps?.onInputChange?.(event, newInputValue, "input");
            }}
            renderInput={(params) => (
              <RenderInput
                rules={rules}
                required={required}
                label={label}
                textFieldProps={textFieldProps}
                error={error}
                icon={icon}
                isLoading={isLoading}
                value={value}
                inputRef={ref}
                {...params}
              />
            )}
            filterOptions={(option) => option}
            {...fieldRest}
            sx={{
              "& .MuiInput-root:before": {
                borderBottom: 0,
              },
              "& .MuiInput-root:after": {
                borderBottomWidth: 0,
              },
              "&  .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
                borderRadius: "50px",
                backgroundColor: "rgb(0, 86, 190)",
                paddingTop: "0px",
                paddingBottom: "0px",
                paddingLeft: "20px",
              },
            }}
          />
        );
      }}
    />
  );
}

const RenderInput = ({
  rules,
  required,
  label,
  textFieldProps,
  error,
  icon,
  isLoading,
  value,
  inputRef,
  ...params
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) => (
  <Box sx={{ position: "relative" }}>
    <TextField
      name={name}
      required={rules?.required ? true : required}
      label={label}
      {...textFieldProps}
      {...params}
      error={!!error}
      InputProps={{
        ...params.InputProps,
        startAdornment: <Icon type={icon} flex />,
        endAdornment: (
          <>
            {isLoading ? <PulseLoader color="gray" size={3} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
        ...textFieldProps?.InputProps,
      }}
      inputProps={{
        ...params.inputProps,
        ...textFieldProps?.inputProps,
        sx: {
          color: "white",
          fontSize: "16px",
          fontWeight: 500,
          fontFamily: "poppins !important",
          "&::placeholder": {
            color: "white",
            opacity: 1,
          },
        },
      }}
      placeholder={value ? value.value?.name : textFieldProps.placeholder}
      helperText={error ? error.message : textFieldProps?.helperText}
      inputRef={inputRef}
      size="medium"
      margin="dense"
      variant="outlined"
    />
  </Box>
);
