import { MouseEvent, useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, IconButtonProps, InputAdornment } from "@mui/material";
import { FieldValues } from "react-hook-form";
import { TextFieldElement, TextFieldElementProps } from "react-hook-form-mui";

import { Icon } from "../components/Icon";

export type PasswordElementProps<T extends FieldValues> =
  TextFieldElementProps<T> & {
    iconColor?: IconButtonProps["color"];
    isStartAdornment?: boolean;
  };

export function PasswordElement<TFieldValues extends FieldValues>({
  iconColor,
  name,
  isStartAdornment = true,
  ...props
}: PasswordElementProps<TFieldValues>) {
  const [password, setPassword] = useState<boolean>(true);
  return (
    <TextFieldElement
      name={name}
      {...props}
      InputProps={{
        startAdornment: isStartAdornment && (
          <InputAdornment position="start">
            <Icon type="lock-blue" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position={"end"}>
            <IconButton
              onMouseDown={(e: MouseEvent<HTMLButtonElement>) =>
                e.preventDefault()
              }
              onClick={() => setPassword(!password)}
              tabIndex={-1}
              color={iconColor ?? "default"}
            >
              {password ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{
        sx: {
          fontSize: "16px",
        },
      }}
      type={password ? "password" : "text"}
    />
  );
}
