import { add, isAfter } from "../utils/datetime";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const areValuesValid = (values: any) => {
  if (values == null) {
    return false;
  }

  if (values.legs == null || values.legs.length === 0) {
    return false;
  }

  if ((values.numberOfSeats?.passengers ?? 0) === 0) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const invalidLegs = values.legs.reduce((acc: any, leg: any) => {
    if (leg.departureAirport == null || leg.destinationAirport == null) {
      return acc + 1;
    }

    if (
      values.flightType.constantKey === "FLIGHTTYPE_ROUND_TRIP" &&
      isAfter(leg.departureAt, add(leg.returnAt, 1, "minute"))
    ) {
      return acc + 1;
    }

    return acc;
  }, 0);

  return invalidLegs <= 0;
};
