import { useAuthentication } from "@fllite/ui/hooks";
import { UserBox } from "@fllite/ui/components";

import { menuItems } from "./menuItems";
import { NavigationLinkList } from "./Navigation.style";
import { NavigationLink } from "./NavigationLink";

export const Desktop = () => {
  const { user } = useAuthentication();

  return (
    <>
      {user !== false && user?.confirmed && (
        <NavigationLinkList>
          {menuItems.map((menuItem) => (
            <NavigationLink
              href={menuItem.href}
              dataTestid={menuItem.dataTestid}
              key={menuItem.id}
            >
              {menuItem.title}
            </NavigationLink>
          ))}
        </NavigationLinkList>
      )}

      <UserBox />
    </>
  );
};
