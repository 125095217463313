import { FC, useRef } from "react";

// TODO properties in component are commented out because they don't fit the types

// import { CalendarMonthOutlined } from "@mui/icons-material";
// import { Stack } from "@mui/material";
import { DatePickerElement as MuiDatePickerElement } from "react-hook-form-mui/date-pickers";

interface DatePickerElementProps {
  name: string;
  small?: boolean;
  disabled?: boolean;
  minDate?: string;
  maxDate?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputProps?: any;
  textReadOnly?: boolean;
  label?: string;
}

export const DatePickerElement: FC<DatePickerElementProps> = ({
  name,
  // small,
  label,
  minDate,
  maxDate,
  ...props
}) => {
  const wrapperRef = useRef(null);
  // const [anchorEl, setAnchorEl] = useState(null); // we have to use state to force rerender, because MUI DatePicker is not rerendering when anchorEl is changed

  return (
    <div
      ref={wrapperRef}
      onClick={() => {
        // setAnchorEl(wrapperRef.current);
      }}
    >
      <MuiDatePickerElement
        closeOnSelect
        // components={{
        //   OpenPickerIcon: () => (
        //     <Stack
        //       onClick={() => setAnchorEl(wrapperRef.current)}
        //       alignItems="center"
        //     >
        //       <CalendarMonthOutlined />
        //     </Stack>
        //   ),
        // }}
        // componentsProps={{
        //   actionBar: {
        //     actions: ["clear"],
        //   },
        // }}
        minDate={minDate != null ? new Date(minDate) : undefined}
        maxDate={maxDate != null ? new Date(maxDate) : undefined}
        name={name}
        label={label}
        {...props}
        // TODO
        // InputProps={{
        //   sx: small
        //     ? { fontSize: "14px", "& input": { padding: "8.5px 14px" } }
        //     : {},
        // }}
        // PopperProps={{
        //   placement: "bottom-end",
        //   anchorEl,
        // }}
      />
    </div>
  );
};
