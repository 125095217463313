/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "@emotion/styled";
import { KeyboardArrowDown } from "@mui/icons-material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
} from "react-hook-form";

import { Icon, IconKeys } from "../components/Icon";

export type MultiSelectProps<T extends FieldValues> = Omit<
  SelectProps,
  "value"
> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: { id: string | number; label: string }[] | any[];
  label?: string;
  itemKey?: string;
  itemValue?: string;
  itemLabel?: string;
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation?: any;
  name: Path<T>;
  parseError?: (error: FieldError) => string;
  minWidth?: number;
  menuMaxHeight?: number;
  menuMaxWidth?: number;
  helperText?: string;
  showChips?: boolean;
  control?: Control<T>;
  showCheckbox?: boolean;
  formControlProps?: Omit<FormControlProps, "fullWidth" | "variant">;
  icon?: IconKeys;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const CustomInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: (theme as any).spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: 0,
    fontSize: 16,
    padding: "2.5px 26px 2.5px 0px",
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 0,
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
}));

const CustomArrow = styled(KeyboardArrowDown)`
  transition: transform 0.2s;
  fill: rgb(00, 86, 190);
`;

export default function MultiSelect<TFieldValues extends FieldValues>({
  options,
  label = "",
  itemKey = "id",
  itemValue = "",
  itemLabel = "label",
  required = false,
  validation = {},
  parseError,
  name,
  menuMaxHeight = ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  menuMaxWidth = 250,
  minWidth = 120,
  helperText,
  showChips,
  control,
  showCheckbox,
  formControlProps,
  icon,
  ...rest
}: MultiSelectProps<TFieldValues>) {
  if (required && !validation.required) {
    validation.required = "This field is required";
  }

  const getLabelForValue = (value: string) =>
    options?.find(({ value: v }) => v === value)?.label;

  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
      }) => {
        helperText = error
          ? typeof parseError === "function"
            ? parseError(error)
            : error.message
          : helperText;
        return (
          <FormControl
            {...formControlProps}
            style={{
              ...formControlProps?.style,
              minWidth,
            }}
            variant={rest.variant}
            fullWidth={rest.fullWidth}
            error={invalid}
            size={rest.size}
            sx={{
              "& .MuiInputBase-root": {
                backgroundColor: "rgba(140, 192, 255, 0.15)",
                paddingLeft: "10px",
                borderRadius: "4px",
              },
            }}
          >
            {label && (
              <InputLabel
                size={rest.size === "small" ? "small" : undefined}
                error={invalid}
                htmlFor={rest.id || `select-multi-select-${name}`}
                required={required}
              >
                {label}
              </InputLabel>
            )}
            <Select
              {...rest}
              id={rest.id || `select-multi-select-${name}`}
              multiple
              label={label || undefined}
              error={invalid}
              displayEmpty={true}
              value={value || []}
              required={required}
              onChange={onChange}
              onBlur={onBlur}
              MenuProps={{
                ...rest.MenuProps,
                PaperProps: {
                  ...(rest.MenuProps?.PaperProps ?? {
                    style: {
                      maxHeight: menuMaxHeight,
                      width: menuMaxWidth,
                      ...rest.MenuProps?.PaperProps?.style,
                    },
                  }),
                },
              }}
              renderValue={customRenderValue({
                showChips,
                getLabelForValue,
                value,
                onChange,
                rest,
              })}
              input={
                <CustomInput
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  startAdornment={<Icon type={icon as any} flex />}
                />
              }
              IconComponent={
                value &&
                Array.isArray(options) &&
                value.length === options?.length
                  ? undefined
                  : CustomArrow
              }
            >
              <OptionsContent
                options={options}
                itemKey={itemKey}
                itemValue={itemValue}
                itemLabel={itemLabel}
                showCheckbox={showCheckbox}
                value={value}
              />
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
}

const customRenderValue = ({
  rest,
  showChips,
  value,
  getLabelForValue,
  onChange,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) =>
  typeof rest.renderValue === "function"
    ? rest.renderValue
    : showChips
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (selected: any) => {
          if ((value && value.length === 0) || !value)
            return (
              <span
                style={{
                  marginLeft: "10px",
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "rgb(00, 86, 190)",
                }}
              >
                Select aircraft
              </span>
            );
          return (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ((selected as any[]) || []).map((selectedValue) => (
                  <Chip
                    key={selectedValue}
                    label={getLabelForValue(selectedValue)}
                    style={{ display: "flex", flexWrap: "wrap" }}
                    onDelete={() => {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange(value.filter((i: any) => i !== selectedValue));
                    }}
                    deleteIcon={
                      <CloseRoundedIcon
                        onMouseDown={(ev) => {
                          ev.stopPropagation();
                        }}
                        sx={{ fill: "rgb(140, 192, 255)" }}
                        fontSize="small"
                      />
                    }
                    sx={{
                      borderRadius: 0,
                      backgroundColor: "transparent",
                      color: "rgb(0, 86, 190)",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "25px",
                    }}
                  />
                ))
              }
            </div>
          );
        }
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (selected: any) => (Array.isArray(selected) ? selected.join(", ") : "");

const OptionsContent = ({
  options,
  itemValue,
  itemKey,
  value,
  showCheckbox,
  itemLabel,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) => (
  <>
    {// eslint-disable-next-line @typescript-eslint/no-explicit-any
    options?.map((item: any) => {
      const val: string | number = item[itemValue || itemKey] || item;
      const isChecked = Array.isArray(value) ? value.includes(val) : false;
      if (isChecked) {
        return;
      }
      return (
        <MenuItem
          key={val}
          value={val}
          sx={{
            fontWeight: (theme) =>
              isChecked
                ? (theme as any).typography.fontWeightBold
                : (theme as any).typography.fontWeightRegular,
          }}
        >
          {showCheckbox && <Checkbox checked={isChecked} />}
          <ListItemText primary={item[itemLabel] || item} />
        </MenuItem>
      );
    })}
    {value && value.length === options?.length && (
      <Box
        sx={{
          padding: "15px 20px",
          display: "flex",
          justifyContent: "center",
          color: "#6e5959",
        }}
      >
        No options
      </Box>
    )}
  </>
);
